
 export const environment = {
    appVersion : '1.0.3',
    baseUrl  :'https://api.prj.weblineapps.com/',
    linuxDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/worktualizestage_1.0.3_amd64.deb',
    macDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/WorktualizeStage-1.0.3.pkg',
    windowsDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/WorktualizeStage-1.0.3.exe',
    userImportSampleTemplateURL : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/SampleUser.xlsx',
    production: false,
  };
